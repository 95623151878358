<template>
  <base-view id="support-faq">
    <view-header title="Support & FAQ" image="bg-support.jpg">
      Still have questions? We've got you covered. Here you can search through
      our library of support articles and videos.
    </view-header>
    <view-section class="relative">
      <div
        id="searchbar"
        class="absolute left-0 right-0 flex flex-col w-auto h-auto max-w-3xl mx-auto my-0 overflow-hidden bg-white border border-white border-solid rounded shadow-lg focus-within:border-primary-light -top-6 hoverbar"
      >
        <div class="flex">
          <span
            class="self-center inline-block px-4 text-2xl font-bold text-neutral-darker flaticon-search-1"
          />
          <input
            type="text"
            name="search"
            id="search"
            placeholder="Search"
            v-model="searchValue"
            class="flex-1 w-40 px-0 py-2 mr-2 text-2xl text-neutral-900 border-0 outline-none"
          />
          <transition name="fadeIn">
            <span
              @click="searchValue = ''"
              v-show="searchValue"
              class="self-center inline-block px-4 text-lg text-neutral-darker cursor-pointer flaticon-cancel"
            />
          </transition>
        </div>
        <transition name="hide">
          <ResultsBox
            v-show="searchValue"
            :searchValue="searchValue"
            id="results-box"
          />
        </transition>
      </div>

      <div class="flex flex-col flex-wrap justify-center pt-16 md:flex-row">
        <CategoryCard
          class="mx-auto mt-0 mb-8 md:mx-8"
          v-for="category in allZendeskData.categories"
          :key="category.id"
          :name="category.name"
          :description="category.description"
          :id="category.id"
        />
      </div>
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewHeader from "@/components/layout/ViewHeader.vue";
import ViewSection from "@/components/layout/ViewSection.vue";

import ResultsBox from "./components/ResultsBox.vue";
import CategoryCard from "./components/CategoryCard.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      searchValue: "",
      hasFocus: true,
      showFilters: false
    };
  },
  components: {
    BaseView,
    ViewHeader,
    ViewSection,
    ResultsBox: ResultsBox,
    CategoryCard: CategoryCard
  },
  computed: {
    ...mapGetters(["allZendeskData"])
  }
};
</script>

<style scoped>
.fadeIn-enter-active {
  transition: opacity 0.25s;
}

.fadeIn-leave-active {
  transition: opacity 0.15s;
}

.fadeIn-enter,
.fadeIn-leave-to {
  opacity: 0;
}

.hide-leave-active {
  transition: all 0s ease 0.1s;
}

#searchbar:not(:focus-within) #results-box {
  display: none;
}
</style>
