<template>
  <div id="results-box" class="flex flex-col border-t">
    <router-link
      v-for="result in results.slice(0, 5)"
      :key="result.item.title"
      class="block px-4 py-2 text-base hover:bg-neutral-light active:bg-neutral"
      :to="'/support/articles/' + result.item.id"
      >{{ result.item.title }}</router-link
    >
    <router-link
      :to="'/support/search/' + searchValue"
      class="block px-4 py-2 text-base border-t first:border-t-0 hover:bg-neutral-light active:bg-neutral"
    >
      <i
        >Show all results for <u>{{ searchValue }}</u></i
      >
    </router-link>
  </div>
</template>

<script>
import Fuse from "fuse.js";

export default {
  props: {
    searchValue: String
  },
  computed: {
    articles() {
      return this.$store.getters.allZendeskData.articles;
    },
    results: function() {
      //console.log(this.search(this.searchValue));
      return this.search(this.searchValue);
    }
  },
  methods: {
    search(value) {
      if (value !== "" && this.articles) {
        var fuse = new Fuse(this.articles, {
          keys: ["title", "body"]
        });
        return fuse.search(value);
      } else {
        return [];
      }
    },
    routeName(id) {
      return this.$store.getters.routeNameBySectionId(id);
    }
  }
};
</script>
