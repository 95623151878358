<template>
  <router-link :to="categoryLink()">
    <div
      class="px-6 py-8 text-center transition-colors rounded-md shadow-md bg-neutral-lighter group hover:text-white hover:bg-primary-light active:bg-primary"
    >
      <span
        class="inline-block text-primary text-7xl group-hover:text-white"
        :class="categoryIcon()"
      />
      <p class="m-0 mt-2 text-2xl font-semibold group-hover:text-white">
        {{ name }}
      </p>
      <p class="m-0 mt-4">{{ description }}</p>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    name: String,
    description: String,
    id: Number
  },
  methods: {
    categoryIcon() {
      if (this.id === 360002944813) {
        return "flaticon-lineal";
      } else if (this.id === 360002944833) {
        return "flaticon-management";
      } else {
        return "flaticon-placeholder";
      }
    },
    categoryLink() {
      return "/support/categories/" + this.id;
    }
  }
};
</script>
